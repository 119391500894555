/*
 * 업무구분 : 고객
 * 화 면 명 : MSPCM113M
 * 화면설명 : 지류동의서승인
 * 접근권한 : 지점장
 * 작 성 일 : 2023.02.06
 * 작 성 자 : sh.park2022
 * 
 * 승인 처리 전, 승인 주의사항/사유 바텀시트 존재(순차적)
 */
<template>
  <ur-page-container class="msp" title="지류동의서 승인" :show-title="true" type="subpage" action-type="search" @action-search-click="fn_OpenMSPCM118P" :topButton="true" :topButtonBottom="topButtonBottom">
    <template #frame-header-fixed>
      <!-- 검색 결과 Label -->
      <ur-box-container v-if="isSrchLabel" alignV="start"  direction="column" class="search-result-box bd-b-Ty1 bd-T-Ty1"> 
        <strong class="tit">검색결과</strong>
        <mo-tab-box default-idx="1" primary class="ns-tabs-box-o nw-tab-sub ns-tabs-box2 no-tab">
          <mo-tab-label idx="1" @click="fn_OpenMSPCM118P">
            <span class="txt">{{ lv_OrgData.hofOrg.label }}</span> 
          </mo-tab-label>
          <mo-tab-label idx="2" @click="fn_OpenMSPCM118P">
            <span class="txt">{{ lv_OrgData.dofOrg.label }}</span> 
          </mo-tab-label>
          <mo-tab-label idx="3" @click="fn_OpenMSPCM118P">
            <span class="txt">{{ lv_OrgData.fofOrg.label }}</span> 
          </mo-tab-label>
          <mo-tab-label idx="4" @click="fn_OpenMSPCM118P">
            <span class="tit">컨설턴트</span> 
            <span class="txt">{{ lv_OrgData.cnslt.label }}</span> 
          </mo-tab-label>
          <mo-tab-label idx="5" @click="fn_OpenMSPCM118P">
            <span class="tit">고객</span> 
            <span class="txt">{{ lv_CustNm }}</span> 
          </mo-tab-label>
        </mo-tab-box>
      </ur-box-container>

      <ur-box-container alignV="start" componentid="" direction="column" class="ns-check-sum">
        <mo-list-item>
          <mo-checkbox v-model="isCustAllCheck" @input="fn_AllCheckHandler" size="small" :disabled="lv_IsEmpty"/>
          <div v-if="fn_CmtdCheckCount < 1" class="list-item__contents">
            <div class="list-item__contents__title">
              <span class="sum">총 <strong class="crTy-blue3">{{ fn_CmtdCnsntCustTot }}</strong> 명</span>
            </div>
          </div>
          <div v-else class="list-item__contents">
            <div class="list-item__contents__title">
              <span class="sum"><strong class="crTy-blue3">{{ lv_CheckCount }}</strong> 명 선택</span>
            </div>
          </div>
        </mo-list-item>
      </ur-box-container>
    </template>

    <!-- Content영역 -->
    <ur-box-container direction="column" alignV="start" class="msp-cm-wrap"> 

      <ur-box-container v-if="!lv_IsEmpty" alignV="start" componentid="" direction="column" class="ns-check-list mb50" :class="{'mb200' : fn_CmtdCheckCount > 0}">
        <mo-list :list-data="lv_CnsntCustList">
          <template #list-item="{item}">
            <mo-list-item :class="{'on' : (item.checked && !item.aprvDisable)}">
              <mo-checkbox v-model="item.checked" @input="fn_AprvHndle()" :disabled="item.aprvDisable"/>
              <div class="list-item__contents">
                <div class="list-item__contents__title">
                  <span class="name txtSkip">{{item.custNm}} ({{ item.mnCustRltnCdNm }})</span>
                </div>
                <div class="list-item__contents__info">
                  <span>{{item.idNum}},ARS 입력 {{item.ars}}</span>
                </div>
                <div class="list-item__contents__info">
                  <span>{{item.celno}}</span>
                </div>
                <div class="list-item__contents__info">
                  <span v-if="item.arsPrcusYn01==='Y' && item.arsPrcusYn02==='Y'">
                    <span class="crTy-bk1">필수컨설팅</span>
                  </span>
                </div>
                <div class="list-item__contents__info">
                  <mo-button v-if="imgBtnShow" color="normal" shape="border" size="small" class="blue" @click="fn_AprvImgSheet">동의서확인</mo-button>
                  <mo-icon v-if="isAprvImgCnfm" icon="msp-is-checked" class="ml10 fs26"></mo-icon><span v-if="isAprvImgCnfm" class="ml4 crTy-bk7 pb5">확인 완료</span>
                </div>
              </div>
            </mo-list-item>
          </template>
        </mo-list>
      </ur-box-container>

      <!-- NoData 영역: start  -->
      <ur-box-container v-else alignV="start" componentid="ur_box_container_002" direction="column" class="ns-contract-list no-data">
        <mo-list-item>
          <div class="list-item__contents">
            <div class="list-item__contents__info">
              <span>데이터가 없습니다.</span>
            </div>
          </div>
        </mo-list-item>
      </ur-box-container>
      <!-- NoData 영역: end  -->

      <!-- 바텀시트 영역 ::START -->
      <!-- 하단 버튼(전화/문자/고객카드/더보기) -->
      <div class="ns-bottom-sheet">
        <mo-bottom-sheet ref="bottomNaviMenu" :close-btn="false" :class="positionUp ? 'ns-bottom-sheet ns-style3 only-btn positionUp' : 'ns-bottom-sheet ns-style3 only-btn'" noHeader>
          <template v-slot:action>
            <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative-bs pal2430 noshadow">
              <div class="relative-div">
                <mo-button componentid="" color="primary" shape="primary" size="medium" @click="fn_ValidationCheck" class="ns-btn-round blue" :disabled="lv_disableAprv">승인</mo-button>
              </div>
            </ur-box-container>
          </template>
        </mo-bottom-sheet>

        <!-- 동의서 확인 -->
        <mo-bottom-sheet ref="sheet1" :close-btn="false"  class="ns-bottom-sheet closebtn">
          <template v-slot:title>
            동의서 확인
            <div class="ns-btn-close" @click="fn_BottomSheetHandler('sheet1', 'close')" name="닫기"></div>
          </template>
          <div class="content-area pb30">
            <ul class="bullet-type-3 crTy-bk7 fs14rem mb10">
              <li>이미지 터치 시 확대할 수 있습니다.</li>
            </ul>
            <!-- 슬라이딩 배너 영역 -->
            <mo-carousel number-of-page="2" width="auto" height="auto" :swiper-option="swiperOption" :prevButton="false" :nextButton="false" noRotate>
              <template #1>
                  <div v-if="!$bizUtil.isEmpty(lv_ImgFronData)" @click="fn_ZoomViewer(0)">
                    <!-- <img src="@/assets/img/temp/img_sense.png" class="w100 h100"/> -->
                    <img v-bind:src="lv_ImgFronData" class="w100 h100" style="aspect-ratio: 1/1.2;"/>
                  </div>
              </template>
              <template #2>
                <div v-if="!$bizUtil.isEmpty(lv_ImgBackData)" @click="fn_ZoomViewer(1)">
                  <!-- <img src="@/assets/img/temp/img_sense.png" class="w100 h100"/> -->
                  <img v-bind:src="lv_ImgBackData" class="w100 h100" style="aspect-ratio: 1/1.2;"/>
                </div>
              </template>
            </mo-carousel>
            <!-- 슬라이딩 배너 영역 //--> 
          </div>

          <template v-slot:action>
            <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative-bs noshadow">
              <div class="relative-div">
                <mo-button componentid="" color="primary" shape="primary" size="border" @click="fn_DocImgConfm" class="ns-btn-round blue">확인</mo-button>
              </div>
            </ur-box-container>
          </template>
        </mo-bottom-sheet>

        <!-- 승인 주의사항 -->
        <mo-bottom-sheet ref="bottomSheet2" class="ns-bottom-sheet closebtn">
          <template v-slot:title>
            승인 주의사항
            <div class="ns-btn-close" @click="fn_BottomSheetHandler('sheet2-2', 'close')" name="닫기"></div>
          </template>
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-check-list">
            <div class="list-item-wrapper bdBN" :class="{'on' : aprvCareCheckList[0].value === true}" @click="aprvCareCheckList[0].value=!aprvCareCheckList[0].value">
              <div class="list-item pt10">
                <div class="list-item__main">
                  <mo-checkbox v-model="aprvCareCheckList[0].value" size="medium"/>
                  <div class="list-item__contents">
                    <div class="list-item__contents__info">
                      <span :class="{'crTy-blue3' : aprvCareCheckList[0].value === true}">고객의 의사에 준하여 자필서명을 진행한 동의서임을 확인 후 승인합니다.</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="list-item-wrapper pt0 bdBN" :class="{'on' : aprvCareCheckList[1].value === true}" @click="aprvCareCheckList[1].value=!aprvCareCheckList[1].value">
              <div class="list-item pt10">
                <div class="list-item__main">
                  <mo-checkbox v-model="aprvCareCheckList[1].value" size="medium"/>
                  <div class="list-item__contents">
                    <div class="list-item__contents__info">
                      <span :class="{'crTy-blue3' : aprvCareCheckList[1].value === true}">승인 후에는 동의처리 결과 알림톡이 고객에게 발송됩니다.</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="list-item-wrapper pt0 bdBN" :class="{'on' : aprvCareCheckList[2].value === true}" @click="aprvCareCheckList[2].value=!aprvCareCheckList[2].value">
              <div class="list-item pt10">
                <div class="list-item__main">
                  <mo-checkbox v-model="aprvCareCheckList[2].value" size="medium"/>
                  <div class="list-item__contents">
                    <div class="list-item__contents__info">
                      <span :class="{'crTy-blue3' : aprvCareCheckList[2].value === true}">지점장/지점프로 대상으로 승인내역이 싱글로 전송됩니다. 원본 회수 관리자 서명 후 기존 가이드에 준하여 보관하시기 바랍니다.</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="list-item-wrapper pt0 bdBN" :class="{'on' : aprvCareCheckList[3].value === true}" @click="aprvCareCheckList[3].value=!aprvCareCheckList[3].value">
              <div class="list-item pt10">
                <div class="list-item__main">
                  <mo-checkbox v-model="aprvCareCheckList[3].value" size="medium"/>
                  <div class="list-item__contents">
                    <div class="list-item__contents__info">
                      <span :class="{'crTy-blue3' : aprvCareCheckList[3].value === true}">원본 미회수 시 승인 취소처리 진행 바랍니다.</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ur-box-container>
          <template v-slot:action>
            <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative-bs noshadow">
              <div class="relative-div">
                <mo-button componentid="" color="primary" shape="primary" size="border" @click="fn_BottomSheetHandler('sheet2-1', 'close')" class="ns-btn-round blue" :disabled="!fn_CmtdIsAllCheckList">확인</mo-button>
              </div>
            </ur-box-container>
          </template>
        </mo-bottom-sheet>

        <!-- 지류동의 승인 사유 선택 -->
        <mo-bottom-sheet ref="bottomSheet3" class="ns-bottom-sheet closebtn">
          <template v-slot:title>
            지류동의 승인 사유 선택
            <div class="ns-btn-close" @click="fn_BottomSheetHandler('sheet3', 'close')" name="닫기"></div>
          </template>
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-radio-list2 mb30">
            <div class="list-item-wrapper">
              <div class="list-item pt10 pb10 bdBN" :class="{'on' : cnstAprvRdo === '01'}" @click="cnstAprvRdo = '01'">
                <div class="list-item__main">
                  <mo-radio v-model="cnstAprvRdo" value="01" checked></mo-radio>
                  <div class="list-item__contents fs17rem " :class="{'crTy-blue3' : cnstAprvRdo === '01'}">본인인증 수단 없음</div>
                </div>
              </div>
              <div class="list-item pt10 pb10 bdBN" :class="{'on' : cnstAprvRdo === '02'}" @click="cnstAprvRdo = '02'">
                <div class="list-item__main">
                  <mo-radio v-model="cnstAprvRdo" value="02"></mo-radio>
                  <div class="list-item__contents fs17rem " :class="{'crTy-blue3' : cnstAprvRdo === '02'}">외국인 고객</div>
                </div>
              </div>
              <div class="list-item pt10 pb10 bdBN" :class="{'on' : cnstAprvRdo === '03'}" @click="cnstAprvRdo = '03'">
                <div class="list-item__main">
                  <mo-radio v-model="cnstAprvRdo" value="03"></mo-radio>
                  <div class="list-item__contents fs17rem " :class="{'crTy-blue3' : cnstAprvRdo === '03'}">개명고객</div>
                </div>
              </div>
              <div class="list-item pt10 pb10 bdBN" :class="{'on' : cnstAprvRdo === '04'}" @click="cnstAprvRdo = '04'">
                <div class="list-item__main">
                  <mo-radio v-model="cnstAprvRdo" value="04"></mo-radio>
                  <div class="list-item__contents fs17rem " :class="{'crTy-blue3' : cnstAprvRdo === '04'}">기타</div>
                </div>
              </div>
            </div>
          </ur-box-container>
          <template v-slot:action>
            <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative-bs noshadow">
              <div class="relative-div">
                <mo-button componentid="" color="primary" shape="primary" size="border" @click="fn_FinalConfm" class="ns-btn-round blue">확인</mo-button>
              </div>
            </ur-box-container>
          </template>
        </mo-bottom-sheet>
      </div>
      <!-- 바텀시트 영역 ::END -->
    </ur-box-container>

  </ur-page-container>
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
***********************************************************************************/
import Msg from '@/systems/webkit/msg/msg'

import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
import MSPCM118P from '@/ui/cm/MSPCM118P'

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
  ***********************************************************************************/
  // 현재 화면명
  name: "MSPCM113M",
  // 현재 화면 ID
  screenId: "MSPCM113M",

  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
  ***********************************************************************************/
  data () {
    return {
      cnstAprvRdo: '01', // default: '01'
      lv_CnsntAprvRsn: '본인인증 수단 없음', // default: '본인인증 수단 없음'

      lv_IsEmpty: true, // 데이터 존재 여부
      lv_SearchData: {}, // 조회 조건 Object
      lv_OrgData: {},    // 조직 정보 Object
      isSrchLabel: false, // 라벨 노출 여부
      lv_disableAprv: false, // 승인 버튼 disabled

      lv_CnsntCustList: [],
      lv_CustNm: '',
      lv_CheckCount: 0,

      imgDataList: [], // 지류 촬영 이미지
      lv_ImgFronData: '', // 앞면 이미지
      lv_ImgBackData: '', // 뒷면 이미지
      isCustAllCheck: false,
      isAprvImgCnfm: false,
      imgBtnShow: true, // 이미지 존재여부
      isAprvCnfm: false, // 승인 처리 여부

      // 승인주의사항 체크리스트
      aprvCareCheckList: [
        { name: '', check1: false, value: false },
        { name: '', check2: false, value: false },
        { name: '', check3: false, value: false },
        { name: '', check4: false, value: false },
      ],
      
      // 정보활용동의 승인 시 필요한 데이타 VO
      lv_AgreeTypeVO: {}, 

      lv_RstArr: [], // 승인완료시 참조 데이타
      alertProps: {}, // 알럿 Props

      positionUp: false,  // 하단버튼 바텀시트 오픈여부

      // index-scroll 초성스크롤 위치지정  스크롤에 따른 위치변경은 개발에서
      indexStyle: {
        top:'150px',
        bottom:'100px',
        height:'calc(100vh - 345px)',
        position:'fixed'
      },
      swiperOption: {
        // centerSlides: true,
        slidesPerView: 1,
        spaceBetween: 20,
        autoplay: false,
        /* autoplay: {
          delay: 5000,
          disableOnInteraction: false, 
        }, */
      },
    };
  },
  /***********************************************************************************
   * watch 정의 영역                                                                 *
  ***********************************************************************************/
  watch: {
    // 조회 조직 데이터
    lv_SearchData () {
      if (!_.isEmpty(this.lv_SearchData)) {
        this.lv_OrgData = this.lv_SearchData.orgData
        this.isSrchLabel = true
      } else {
        this.isSrchLabel = false
      }
    },
    // 전체 선택 체크박스
    lv_CheckCount () {
      // 미승인고객 리스트
      let unAprvCustList = this.lv_CnsntCustList.filter(item => {
          return !item.aprvDisable
        });

      // 모두 선택시, 체크 On
      if (!_.isEmpty(this.lv_CnsntCustList) && this.fn_CmtdCheckCount === unAprvCustList.length) {
        this.isCustAllCheck = true
      } else {
        this.isCustAllCheck = false
      }
    },
    // 지류동의 승인 사유
    cnstAprvRdo (value) {
      switch (value) {
        case '01':
          this.lv_CnsntAprvRsn = '본인인증 수단 없음'
          break;
        case '02':
          this.lv_CnsntAprvRsn = '외국인 고객'
          break;
        case '03':
          this.lv_CnsntAprvRsn = '개명고객'
          break;
        case '04':
          this.lv_CnsntAprvRsn = '기타'
          break;
      }
    },
  },
  /***********************************************************************************
   * Computed 함수 정의 영역                                                         *
  ***********************************************************************************/
  computed: {
    // 전체 고객 수
    fn_CmtdCnsntCustTot () {
      let custList = this.lv_CnsntCustList
      return custList ? custList.length : 0
    },
    // 체크 고객 수
    fn_CmtdCheckCount () {
      if (this.fn_CmtdCnsntCustTot < 1) return 0;

      let chkList = this.lv_CnsntCustList.filter(item => {
          return item.checked && !item.aprvDisable
        });

      // css flag 변경, 승인 버튼 show/hide
      if (chkList.length > 0) {
        // this.positionUp = true
        this.$refs.bottomNaviMenu.open()
      } else {
        // this.positionUp = false
        this.$refs.bottomNaviMenu.close()
      }

      // 체크된 고객 있을경우 GNB 변경 (전화/문자/고객카드/더보기)
      // this.$cmUtil.setSelectedCustList(this, chkList)

      this.lv_CheckCount = chkList.length
      return chkList.length
    },
    // 체크된 고객 리스트
    fn_CmtdCheckCustList () {
      let rtnList = []

      rtnList = this.lv_CnsntCustList.filter(item => {
          return item.checked && !item.aprvDisable
        });

      return rtnList
    },
    // 승인주의사항 체크 리스트 확인
    fn_CmtdIsAllCheckList () {
      let rtnValue = false

      let checkList = this.aprvCareCheckList.filter(item => {
          return item.value === true
        });

      // 체크리스트 모두 클릭시 확인 버튼 활성화
      rtnValue = checkList.length === this.aprvCareCheckList.length
      return rtnValue
    },
    topButtonBottom () {
      // topButton을 positionUp 클래스가 적용된 바텀시트의 위쪽으로 올라오게 함
      if (this.positionUp) return 150
      else return 0
    }
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
  ***********************************************************************************/
  created() {
    this.lv_UserInfo = this.getStore('userInfo').getters.getUserInfo

    // backKey event 등록
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack)
  },
  mounted() {
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog('MSPCM113M')

    this.fn_Init()
  },
  beforeDestroy() {
    // backKey event 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack)
  },
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    /**************************************************************************************
     * @function fn_Init
     * @notice   최초실행함수 / 디바이스 뒤로가기 (고객조회내역 있을경우 Init)
     *           1. 정보활용동의 승인 시 필요한 VO 셋팅
     *           2. 상세조회팝업 유도(BotoomAlert)
    **************************************************************************************/
    fn_Init () {
      // 조회, 셋팅 모두 초기화
      this.lv_IsEmpty = true
      this.lv_CnsntCustList = []
      this.lv_SearchData = {}

      // Setting ::정보활용동의 승인 시 필요한 데이타 VO
      this.lv_AgreeTypeVO = {
        infoPrcusCnsntYn: 'Y', // 정보활용동의여부
        prcsfCd: 'I', // 프로세스 플래그코드
        chnlCustId: '', // 채널고객ID
        mnCustRltnCd: '', // 관계 (01: 본인, 03: 자녀)
        custNm: '', // 고객명
        unAprvCustYn: '', // 미승인고객여부
        selfClmnyCustYn: 'N', // 본인수금고객여부
        mktCnsntAvlPrd: 0, // 마케팅 동의 기간
        mktCnvsMthTelYn: 'N', // 마케팅권유방법전화여부
        mktCnvsMthSmsYn: 'N', // 마케팅권유방법SMS여부
        mktCnvsMthEmailYn: 'N', // //마케팅권유방법이메일여부
        mktCnvsMthPstYn: 'N' // 마케팅권유방법우편여부
      }

      // CM118P.vue Open 유도
      let alertMsg = ''
      let callBackFnc = null

      // FC, TC채널 지점장만 메뉴 사용가능
      if (['FC', 'TC'].includes(this.lv_UserInfo.chnlScCd)) {
        alertMsg = '담당 컨설턴트를 선택한 뒤 승인하고자하는 고객명을 입력해주세요.'
        callBackFnc = this.fn_OpenMSPCM118P
      } else {
        alertMsg = '권한이 없습니다.'
      }

      this.alertProps = {single: true, content: alertMsg}
      this.fn_ShowBottomAlert(callBackFnc)
    },
    /***************************************************************
     * @function fn_AllCheckHandler
     * @name     전체선택
     * @notice   미승인 고객에 대한 전체선택 on/off
    ***************************************************************/
    fn_AllCheckHandler () {
      const lv_Vm = this

      lv_Vm.lv_CnsntCustList.forEach(item => {
          if (!item.aprvDisable) {
            item.checked = lv_Vm.isCustAllCheck
            // this.$set(item, 'checked', lv_Vm.isCustAllCheck)
          }
        });
        
    },
    /**************************************************************************************
     * @function fn_AprvImgSheet
     * @notice   동의서 촬영 이미지 confirm (BottomSheet)
     *            - 바텀시트 Open
    **************************************************************************************/
    fn_AprvImgSheet () {
      if (this.$bizUtil.isEmpty(this.lv_ImgFronData) && 
            this.$bizUtil.isEmpty(this.lv_ImgBackData)) {

        this.alertProps = {title: '', single: true, content: '동의서 이미지가 없습니다.'}
        this.fn_ShowBottomAlert()
      } else {
        this.fn_BottomSheetHandler('sheet1')
      }
    },
    /**************************************************************************************
     * @function fn_AprvHndle
     * @notice   전체 선택 클릭 이벤트
    **************************************************************************************/
    fn_AprvHndle () {
      this.selectItems = this.lv_CnsntCustList.filter(item => {
          return item.checked && !item.lv_disableAprv
        });
    },
    /**************************************************************************************
     * @function fn_OpenMSPCM118P
     * @notice   고객명 검색 팝업 호출
    **************************************************************************************/
    fn_OpenMSPCM118P () {
      const lv_Vm = this

      // FC, TC채널 지점장만 메뉴 사용가능
      if (!['FC', 'TC'].includes(this.lv_UserInfo.chnlScCd)) {
        let alertMsg = '권한이 없습니다.'
        this.alertProps = {single: true, content: alertMsg}
        this.fn_ShowBottomAlert()
        return
      }

      let lv_popup118 = this.$bottomModal.open(MSPCM118P, {
        properties: {
          pOrgData: this.lv_OrgData
        },
        listeners: {
          onPopupConfirm: (pData) => {
            if (!_.isEmpty(pData)) {
              lv_Vm.fn_PopupCallback(pData)
            }
            this.$bottomModal.close(lv_popup118);
          },
          onPopupClose: () => {
            this.$bottomModal.close(lv_popup118);
          }
        }
      });

    },
    /**************************************************************************************
     * @function fn_PopupCallback
     * @notice   상세조회팝업 CallBack Function
     *           고객 정보 Setting
    **************************************************************************************/
    fn_PopupCallback (pData) {
      // 승인 확인 초기화
      this.isAprvCnfm = false
      this.isAprvImgCnfm = false

      this.lv_SearchData = pData

      this.lv_OrgData = pData.orgData
      this.lv_CustNm = pData.custNm

      this.fn_GetCnsntDetailInfo(pData)
      this.fn_SetImageData(pData)
    },
    /******************************************************************************
     * @function fn_GetCnsntDetailInfo
     * @notice   정보_활용_동의_승인_요청 목록 조회
    ******************************************************************************/
    fn_GetCnsntDetailInfo (custInfo) {
      const lv_Vm = this

      let pParams = {
        custNm: custInfo.custNm, // 고객명
        cnsltNo: custInfo.cnsltNo, // 컨설턴트ID
        chnlCustId: custInfo.chnlCustId, // 채널고객ID
        chnlCstgrId: custInfo.chnlCstgrId, // 채널그룹ID
        infoPrcusCnsntStatCd: '01', // 정보활용동의 처리상태코드 - 01 : 신청 , 02 : 승인 , 03 : 승인취소
        infoPrcusCnsntTypCd: '18', // 정보활용동의 유형 - 17 : 필동 , 18 : 필동+마동 , 19 : 마동
        infoPrcusCnsntPathCd: '18' // 정보활용동의경로코드 - 01 : 동의서 , 02 : 휴대폰 , 03 : 신용카드 ... 17 : 사랑On영업관리자승인 , 18 : 사랑On미니영업관리자승인
      }

      const trnstId = 'txTSSCM82S3'
      const auth = 'S'
      this.post(lv_Vm, pParams, trnstId, auth)
        .then(res => {
          if (!_.isEmpty(res.body)) {
            let cnsntCustVO = res.body.iCCustInfoPrcusCnsntVO

            cnsntCustVO.forEach(item => {
              if (!this.$bizUtil.isEmpty(item.infoPrcusCnsntDalwRsn)) {
                let strList = item.infoPrcusCnsntDalwRsn.split(' - ')

                if (strList.length > 2) {
                  strList[2] = strList[2].substr(0,8)
                }

                item.infoPrcusCnsntDalwRsn = strList.join(' - ')
              }
            })

            lv_Vm.fn_SetCnsntCustList(cnsntCustVO)
          }
        })
        .catch(error => {
          window.vue.error(error)
        });

    },
    /**************************************************************************************
     * @function fn_SetImageData
     * @name     NAS_이미지_로드
     * @param custInfoData (주고객)
     * @notice NAS에 찾을 이미지 파일 존재여부를 확인하고, base64(String)를 response로 받는다
    **************************************************************************************/
    fn_SetImageData (custInfoData) {
      const lv_Vm = this

      // 초기화
      this.imgBtnShow = false
      this.lv_ImgFronData = ''
      this.lv_ImgBackData = ''
      
      let pParams = {
        chnlCtgrId: custInfoData.chnlCstgrId,
        chnlCustId: custInfoData.chnlCustId
      }
      const trnstId = 'txTSSCM14S0'
      const auth = 'S'
      this.post(lv_Vm, pParams, trnstId, auth)
        .then(res => {
          if (res.body && res.body.trnsRsltCd === 'S') {
            if (!this.$bizUtil.isEmpty(res.body.foreImgData) && !this.$bizUtil.isEmpty(res.body.backImgData)) {

              lv_Vm.imgDataList = res.body.aprvCustList
              lv_Vm.lv_ImgFronData = res.body.foreImgData
              lv_Vm.lv_ImgBackData = res.body.backImgData
              
              lv_Vm.imgDataList.forEach((item, index) => {
                item.index = index
                item.base64Data = index < 1 ? lv_Vm.lv_ImgFronData : lv_Vm.lv_ImgBackData
              });
  
            }
          }

          // 이미지 하나라도 없으면 동의서 확인 미노출
          if (this.$bizUtil.isEmpty(res.body.foreImgData) || this.$bizUtil.isEmpty(res.body.backImgData)) {
            lv_Vm.imgBtnShow = false
          } else {
            lv_Vm.imgBtnShow = true
          }
        })
        .catch(error => {
          window.vue.error(error)
          lv_Vm.imgBtnShow = false
        });

    },
    /**************************************************************************************
     * @function fn_SetCnsntCustList
     * @notice   초기 고객 데이터 Set
    **************************************************************************************/
    fn_SetCnsntCustList (cnsntCustVO) {
      let tmpArr = cnsntCustVO || []

      tmpArr.forEach(value => {
        value.aprvCnfmYn = 'N' // 동의서 확인 여부(defualt: 'N')
        value.subCheckAll = value.subCheck1 = value.subCheck2 = value.subCheck3 = value.subCheck4 = false // 전체,전화,문자,이메일,우편 체크여부
        value.idNum = value.knbEncr.slice(0, 6) + '-' + value.knbEncr.slice(6, 7) // knb 마스킹
        value.ars = (value.arsPrcusYn01 === 'Y' ? 'Y' : 'N') + '/' + (value.arsPrcusYn02 === 'Y' ? 'Y' : 'N') // ARS 입력
        value.aprvDisable = value.unAprvCustYn === 'N' ? true : false // Y: 미승인, N: 승인
        value.checked = value.unAprvCustYn === 'N'

        if (process.env.NODE_ENV === 'local' || process.env.NODE_ENV === 'development') { // 개발계, Local테스트를 위한 파라미터 변경
          value.confirmHologSignYn = 'Y'
          value.hologSignYn = 'Y'
        } else {
          value.confirmHologSignYn = 'N'
        }
      });

      let aprvCustList = tmpArr.filter(item => {
        return item.unAprvCustYn === 'N' // 승인고객
      })
      this.isAprvImgCnfm = aprvCustList.length === tmpArr.length // 조회된 고객 모두 승인고객이라면, 확인완료

      this.lv_IsEmpty = _.isEmpty(tmpArr)
      this.lv_CnsntCustList = tmpArr
    },
    /**************************************************************************************
     * @function fn_HistoryBack
     * @notice   디바이스 뒤로가기 버튼
    **************************************************************************************/
    fn_HistoryBack () {
      if (!_.isEmpty(this.lv_SearchData)) {
        // 초기 함수 실행
        this.fn_Init()
      } else {
        // 조회를 하지 않았으면 뒤로가기
        this.$router.go(-1)
      }
    },
    /**************************************************************************************
     * @function fn_DocImgConfm
     * @notice   동의서 확인 Event
    **************************************************************************************/
    fn_DocImgConfm () {
      for (let n in this.lv_CnsntCustList) {
        this.lv_CnsntCustList[n].aprvCnfmYn = 'Y'
      }

      this.isAprvImgCnfm = true
      this.fn_BottomSheetHandler('sheet1', 'close')
    },
    /**************************************************************************************
     * @function fn_ClickAprv
     * @notice   승인 버튼 Click Event
     *            fn_ValidationCheck 실행후, 실행되는 함수
    **************************************************************************************/
    fn_ClickAprv () {
      let contentMsg = ''

      let chkArsList = this.fn_CmtdCheckCustList.filter(item => {
        return item.arsPrcusYn01 !=='Y' && item.arsPrcusYn02 !== 'Y'
      })
      
      if (chkArsList.length > 0) {
        let custNmList = []

        chkArsList.forEach(item => {
          custNmList.push(item.custNm)
        })

        let custNm = custNmList.join(', ')
        contentMsg = custNm + ' 고객님은 ARS일련번호 미입력으로 필수컨설팅동의 처리가 불가합니다.'
      } else {
        if (!this.isAprvImgCnfm) {
          contentMsg = '동의서 이미지 확인 후 승인이 가능합니다.'
        }
      }

      if (!this.$bizUtil.isEmpty(contentMsg)) {
        // Alert
        this.alertProps = {title: '', single: true, content: contentMsg}
        this.fn_ShowBottomAlert()
        return
      }
      
      // 승인 체크 리스트 초기화
      this.aprvCareCheckList.forEach(item => {
        item.value = false
      });

      // 승인 사유 초기화
      this.cnstAprvRdo = '01'

      // 승인 주의사항 시트 Open
      this.fn_BottomSheetHandler('sheet2')
    },
    /**************************************************************************************
     * @function fn_FinalConfm
     * @notice   승인처리 마지막 Confirm Alert
    **************************************************************************************/
    fn_FinalConfm () {
      this.fn_BottomSheetHandler('sheet3', 'close')

      let custNmList = []
      this.fn_CmtdCheckCustList.forEach(item => {
        custNmList.push(item.custNm)
      })
      let contCustNm = custNmList.join(', ')

      let contentMsg = '개인정보보호법은 고객 동의없이 정보를 저장/이용하는 등의 행위를 엄격히 금지합니다.(법규위반시 5년 이하 징역이나 5천만원 이하 벌금에 처할 수 있음)<br>고객자필 동의서 확인을 철저히 해주시기 바랍니다.<br>아래 고객의 정보활용동의 승인하시겠습니까?'
      contentMsg += '<br><br><strong>{0} : 필수컨설팅</strong>'.replace('{0}', contCustNm)
      
      this.alertProps = {title: '', content: contentMsg}
      this.fn_ShowBottomAlert(this.fn_AprvConfirm)
    },
    /**************************************************************************************
     * @function fn_AprvConfirm
     * @notice   필동, 마동 승인처리
    **************************************************************************************/
    fn_AprvConfirm () {
      const lv_Vm = this

      let pParams = this.fn_GetConfirmData()
      if (_.isEmpty(Object.keys(pParams))) return

      const trnstId = 'txTSSCM82U1'
      const auth = 'S'
      this.post(lv_Vm, pParams, trnstId, auth)
        .then(res => {
          if (!_.isEmpty(res.body) && !_.isEmpty(res.body.agreeTypeMcnstList)) {
            let tmpArr1 = res.body.agreeTypeMcnstList || [] // 필동
            let niceFailList = []
            let failCustList = []

            tmpArr1.forEach(item => {
              // 필동 메시지 처리
              item.trtRsltMsg = lv_Vm.fn_GetNiceAuthResultMsg(item.custNm, item.trtRsltCntnt)
              // NICE실명 인증 실패
              if (item.trtRsltCntnt !== 'EICU201') {
                niceFailList.push(item)
                failCustList.push(item.custNm)
              }
            });

            if (process.env.NODE_ENV === 'local') { // 개발계, Local테스트를 위한 파라미터 변경
              lv_Vm.lv_disableAprv = true
              lv_Vm.fn_SaveCnsntAprv()
              lv_Vm.alertProps = {single: true, content: lv_Vm.lv_CustNm + '님의 동의 신청이 완료되었습니다.<br>실명확인 결과, 정상입니다.'}
              lv_Vm.fn_ShowBottomAlert()
              return
            }

            if (_.isEmpty(niceFailList)) { // NICE실명 모두 성공
              lv_Vm.lv_disableAprv = true
              lv_Vm.fn_SaveCnsntAprv()
              lv_Vm.alertProps = {single: true, content: niceFailList[0].trtRsltMsg}
              lv_Vm.fn_ShowBottomAlert()
            } else {
              let failCust = failCustList.join('님, ')
              let failMsg = failCust + this.$t('cm')['ECMC089'] // 고객은 실명정보에 문제가 있어 동의처리가 불가합니다.<br>(개명, 귀화, 신용거래 이력이 없는 경우 등)<br><br>해당 경우는 NICE평가정보 고객센터(1600-1522) 통해서 실명정보 확인 후 처리 부탁드립니다.
              lv_Vm.alertProps = {single: true, content: failMsg}
              lv_Vm.fn_ShowBottomAlert()
            }
          }
        })
        .catch(error => {
          window.vue.error(error)
        });

    },
    /**************************************************************************************
     * @function fn_GetConfirmData
     * @notice   정보활용동의 승인 시 필요한 param 구성
     * @retrun   result - 구성된 Param
    **************************************************************************************/
    fn_GetConfirmData () {
      let tmpArr = [] // 선택한 Row 데이타
      let tmpObj1 = {}

      // 체크된 Row 추출
      tmpArr = this.lv_CnsntCustList.filter(item => {
          return item.checked
        });

      // 기본데이터 구성
      let result = {
        custNm: this.lv_CustNm, // 고객명
        cnsltNo: this.lv_SearchData.cnsltNo, // 컨설턴트ID
        chnlCustId: this.lv_SearchData.chnlCustId, // 채널고객ID
        chnlCstgrId: this.lv_SearchData.chnlCstgrId, // 채널그룹ID
        agreeTypeMcnstList: [], // 필동 리스트
        agreeTypeMktList: [] // 마동 리스트
      }
      
      // 필동, 마동 데이타 구성
      tmpArr.forEach(value => {
        tmpObj1 = {}
        // 필동
        tmpObj1 = Object.assign({}, this.lv_AgreeTypeVO)
        tmpObj1.chnlCustId = value.chnlCustId // 채널고객ID
        tmpObj1.mnCustRltnCd = value.mnCustRltnCd // 관계
        tmpObj1.custNm = value.custNm // 고객명
        tmpObj1.unAprvCustYn = value.unAprvCustYn // 미승인고객여부
        tmpObj1.infoPrcusCnsntStatCd = '02' // 정보활용동의 처리상태코드 - 01 : 신청 , 02 : 승인 , 03 : 승인취소
        tmpObj1.infoPrcusCnsntTypCd = '17' // 정보활용동의 유형 - 17 : 필동 , 18 : 필동+마동 , 19 : 마동
        tmpObj1.infoPrcusCnsntPathCd = '18' // 정보활용동의경로코드 - 01 : 동의서 , 02 : 휴대폰 , 03 : 신용카드 ... 17 : 사랑On영업관리자승인 , 18 : 사랑On미니영업관리자승인

        result.agreeTypeMcnstList.push(Object.assign({}, tmpObj1)) // 필동 기본 데이터
      });

      return result
    },
    /**************************************************************************************
     * @function fn_SaveCnsntAprv
     * @name     동의승인이력저장
     * @notice   
     *           1. DB 업데이트
     *           2. 지점장, 지점프로에게 자필서류 이미지 첨부 싱글메일 발송 (싱글메일 발송실패시, 재발송)
     *              -> 원본 회수&서류와 이미지 대조 목적
     *           3. 메일 발송후, 담당 컨설턴트에게 푸시 알림
    **************************************************************************************/
    fn_SaveCnsntAprv () {
      const lv_Vm = this

      let pCustList = this.fn_SetAprvCustList()

      let pParams = {
        cnsltEno: this.lv_OrgData.cnslt.key, // 컨설턴트사번
        cnsltNm: this.lv_OrgData.cnslt.label, // 컨설턴트명

        aprvCustList: pCustList // 승인 요청 고객 리스트
      }

      const trnstId = 'txTSSCM34S2'
      const auth = 'S'
      this.post(lv_Vm, pParams, trnstId, auth)
        .then(res => {
          // 서버 데이터 가져오기
          if (!_.isEmpty(res.body) && res.body.trnsRsltCd === 'S') {
            // 싱글메일 성공
            console.log('succes', res.body)
          } else {
            if (res.body !== null && res.body.trnsRsltCd === 'Y') {
              // PUSH 성공
              // lv_Vm.getStore('confirm').dispatch('ADD', res.body.trnsRsltCntnt)
              let alertMsg = '동의 승인처리가 완료되었습니다.<br>승인 메일을 확인해주세요.'
              lv_Vm.alertProps = {title: '',single: true, content:alertMsg}
              lv_Vm.fn_SaveCompltAlert()
            } else {
              // 싱글메일 성공할때까지, 재발송
              lv_Vm.alertProps = {
                single: true,
                title_pos_btn: '재발송',
                content: lv_Vm.$t('cm')['ECMC082'] // 싱글메일 발송에 실패하였습니다.<br>재발송을 시도해주세요.
              }

              lv_Vm.fn_ShowBottomAlert(lv_Vm.fn_SaveCnsntAprv)
            }
          }
        })
        .catch(error => {
          window.vue.error(error)
        });

    },
    /**************************************************************************************
     * @function fn_SetAprvCustList
     * @name     승인요청고객리스트
     * @notice   승인 요청 고객 리스트 셋팅
    **************************************************************************************/
    fn_SetAprvCustList () {
      let foreImgNm = this.imgDataList[0].foreImgNm
      let backImgNm = this.imgDataList[0].backImgNm
      let pCustList = []
      this.imgBtnShow = true

      if (_.isEmpty(this.imgDataList)) {
        this.imgBtnShow = false
      }

      this.fn_CmtdCheckCustList.forEach(item => {
        let custObj = {
          chnlCtgrId: item.chnlCstgrId, // 채널그룹ID
          chnlCustId: item.chnlCustId,  // 채널고객ID
          custNm: item.custNm,          // 고객명
          cnsltEno: this.lv_OrgData.cnslt.key, // 담당 컨설턴트 사번
          foreImgNm: foreImgNm, // 앞면 이미지
          backImgNm: backImgNm, // 뒷면 이미지
          cnsntAprvRsn: this.lv_CnsntAprvRsn // 승인 사유
        }

        pCustList.push(custObj)
      });

      return !_.isEmpty(pCustList) ? pCustList : []
    },
    /**************************************************************************************
     * @function fn_ZoomViewer
     * @name     뷰어_플러그인
     * @param    imgIndex(이미지 index)
     * @notice   이미지 확대 플러그인
    **************************************************************************************/
    fn_ZoomViewer (imgIndex) {
      let imgData = imgIndex < 1 ? this.lv_ImgFronData : this.lv_ImgBackData

      window.fdpbridge.exec('ZoomViewerPlugin', {
        img: imgData
      }, () => {
        console.log(':: ZoomPlugin success')
      }, (error) => {
        console.log(':: ZoomPlugin fail')
        window.vue.error(error)
      });

    },
    /**************************************************************************************
     * @function fn_ValidationCheck
     * @notice   승인 선택 시 데이타 유효성 체크
     * @return result - true / false
    **************************************************************************************/
    fn_ValidationCheck () {
      const lv_Vm = this
      let alertFlag = false // 알럿을 띄울지 메시지알럿을 띄울지 구분 - false(알럿), true(메시지팝업)
      let alertMsg1 = ''
      let alertMsg2 = '' // 메시지알럿 텍스트

      // 체크된 Row 추출
      let tmpArr = this.lv_CnsntCustList.filter(item => {
          return item.checked
        });

      for (let i = 0; i < tmpArr.length; i++) {
        // 주민등록번호 확인
        if (tmpArr[i].knbEncr === '' || tmpArr[i].knbEncr.length !== 13) {
          alertMsg1 = tmpArr[i].custNm + '님의 주민번호가 등록되지 않았습니다.'
          alertFlag = true
          break
        }
        
        // 위촉전 교육생
        if (tmpArr[i].zaRecrCndtrYn.trim() === 'Y') {
          alertMsg1 = '「위촉전 교육생」 은 동의 승인이 불가합니다.'
          alertFlag = true
          break
        }

        // ARS 입력 여부
        if (tmpArr[i].arsPrcusYn01 !== 'Y' && tmpArr[i].knbEncr.trim().replace('******', '') === '') {
          alertMsg1 = tmpArr[i].custNm + ' 고객님의 주민번호를 확인할 수 없습니다.<br><br>ARS를 통해 주민번호를 입력하고(고객)지류동의 화면에서 주민번호와 연동된 일련번호를 입력한 경우(컨설턴트)승인 가능합니다.'
          alertFlag = true
          break
        }

        // 부실동의 여부
        if (tmpArr[i].infoPrcusCnsntDalwScCd === '01') {
          alertMsg2 = '[부실동의 예상건 | FC 間 중복동의]\n\n'
          alertMsg2 += '정보활용동의불가사유(' + tmpArr[i].infoPrcusCnsntDalwRsn + ')\n\n'
          alertMsg2 += tmpArr[i].custNm + '는 당사 재직중인 컨설턴트로 확인됩니다.\n'
          alertMsg2 += '- 동의경로 확인 후 컨설턴트 아닌 경우에만 처리바랍니다.\n'
          alertMsg2 += "'FC 間 동의'는 부실동의로 승인을 엄금합니다.\n\n"
          alertFlag = true
          break
        } else if (tmpArr[i].infoPrcusCnsntDalwScCd === '02') {
          // alertMsg2 = '[부실동의 예상건 | FC 間 중복동의]\n\n'
          // alertMsg2 += '정보활용동의불가사유(' + tmpArr[i].infoPrcusCnsntDalwRsn + ')\n\n'
          // alertMsg2 += tmpArr[i].custNm + ' 고객은 위와 같이 당월에 동의처리 되었습니다.\n'
          // alertMsg2 += "- '동일고객 중복동의'는 컴플 점검/관리자 문책대상 입니다.\n"
          // alertFlag = true

		      // 2023.05.23 안내 문구 변경 (이상인 프로 요청)
          alertMsg2 = '[부실동의 예상건]<br>'
          alertMsg2 += '정보활용동의불가사유('+ tmpArr[i].infoPrcusCnsntDalwRsn + ')<br>'
          alertMsg2 += tmpArr[i].custNm + ' 고객은 위와 같이 당월에 동의처리 되었습니다.<br>'
          alertMsg2 += '"동일고객 중복동의"는 컴플 점검/관리자 문책대상이 될 수 있습니다.<br>'
          alertMsg2 += '승인하시겠습니까?'
          alertFlag = true

          break
        }
      } // for i

      // BottomAlert Setting & Open
      if (alertFlag) {
        if (!this.$bizUtil.isEmpty(alertMsg1)) {
          this.alertProps = {title: '',single: true, content:msg}
          this.fn_ShowBottomAlert()
        } else if (!this.$bizUtil.isEmpty(alertMsg2)) {
          this.alertProps = {content: alertMsg2, title_pos_btn: "예", title_neg_btn: "아니오"}
          this.fn_ShowBottomAlert(this.fn_ClickAprv)
        }
      } else {
        this.fn_ClickAprv()
      }
    },
    /**************************************************************************************
     * @function fn_GetNiceAuthResultMsg
     * @notice   NICE실명 인증 처리 결과 메시지 처리
     * @param    custNm       고객명
     * @param    trtRsltCntnt 에러코드
     * @return   msg - 에러코드에 따른 메시지
    **************************************************************************************/
    fn_GetNiceAuthResultMsg (custNm, trtRsltCntnt) {
      let msg = ''
      if (this.$bizUtil.isEmpty(trtRsltCntnt)) return

      if (trtRsltCntnt === 'EICU038') {
        msg += this.$t('cm')['ECMC023'].replace('{0}', custNm) // custNm + '님은 유포털에 동일한 주민번호로 이미 고객이 등록되어 있어 추가 등록이 불가하므로 지점 문의 후 처리 바랍니다.'
      } else if (trtRsltCntnt === 'EICU039') {
        msg += this.$t('cm')['ECMC024'].replace('{0}', custNm) // custNm + '님은 유효종료 고객이므로, 지점에 유효종료 처리 요청바랍니다.'
      } else if (trtRsltCntnt === 'EICU040') {
        msg += this.$t('cm')['ECMC025'].replace('{0}', custNm) // custNm + '님의 동의 신청이 처리가 불가합니다.(EICU040).'
      } else if (trtRsltCntnt === 'EICU101' || trtRsltCntnt === 'EICU102' || trtRsltCntnt === 'EICU103' || trtRsltCntnt === 'EICU104') {
        if (trtRsltCntnt === 'EICU101') {
          msg += this.$t('cm')['ECMC026'].replace('{0}', custNm) // custNm + '님은 실명확인 결과 본인이 아닙니다.'
        } else if (trtRsltCntnt === 'EICU102') {
          msg += this.$t('cm')['ECMC027'].replace('{0}', custNm) // custNm + '님은 실명확인 결과 명의도용 차단 요청 식별번호입니다.'
        } else if (trtRsltCntnt === 'EICU103') {
          msg += custNm + this.$t('cm')['ECMC089'] // custNm + "고객은 실명정보에 문제가 있어 동의처리가 불가합니다.<br>(개명, 귀화, 신용거래 이력이 없는 경우 등)<br><br>해당 경우는 NICE평가정보 고객센터(1600-1522) 통해서 실명정보 확인 후 처리 부탁드립니다."
        } else if (trtRsltCntnt === 'EICU104') {
          msg += custNm + this.$t('cm')['ECMC076'] // custNm + '님의 실명확인 결과, 비정상(불완전 or 대상자불명확 or 거주지불명확) 입니다.'
        }
        msg = msg + ' ' + this.$t('cm')['ECMC028'] // ' 해당 고객에 대한 동의 처리는 지점으로 문의하시기 바랍니다.'
      } else if (trtRsltCntnt === 'EICU201' || trtRsltCntnt === 'EICU202' || trtRsltCntnt === 'EICU203' || trtRsltCntnt === 'EICU204') {
        msg += this.$t('cm')['ECMC029'].replace('{0}', custNm) // custNm + '님의 동의 신청이 완료되었습니다.'
        if (trtRsltCntnt === 'EICU201') {
          msg = msg + ' ' + this.$t('cm')['ECMC030'] // ' 실명확인 결과, 정상입니다.'
        } else if (trtRsltCntnt === 'EICU202') {
          msg = msg + ' ' + this.$t('cm')['ECMC031'] // ' 실명확인 결과 만 14세 미만 미성년자로 확인되므로 다시 한번 고객님의 실명 확인 바랍니다.'
        } else if (trtRsltCntnt === 'EICU203') {
          msg = msg + ' ' + this.$t('cm')['ECMC032'] // ' 실명확인 결과, 비정상(외국인명상이)인점 참고 바랍니다.'
        } else if (trtRsltCntnt === 'EICU204') {
          msg = msg + ' ' + this.$t('cm')['ECMC033'] // ' NICE와 대외계통신 에러인 경우로 실명인증이 진행되지 않은점 참고 바랍니다. 다시 한번 고객님의 실명 확인 바랍니다.'
        }
      } else {
        msg += trtRsltCntnt
      }

      return msg
    },
    /**************************************************************************************
     * @function fn_ShowBottomAlert
     * @name     BottomAlert_Component_호출_함수
     * @param    fn_CallBack(type: function)
     * @notice   Alert Property는 해당 함수 호출전에 셋팅한다.
     *           Alert Confirm Event는 Function을 파라미터로 받는다.
     *                 Confirm/Close: 전역변수로 셋팅된 Property 초기화
    **************************************************************************************/
    fn_ShowBottomAlert (fn_CallBack) {
      const lv_Vm = this

      let lv_Alert = this.$bottomModal.open(MSPBottomAlert, {
        properties: lv_Vm.alertProps,
        listeners: {
          onPopupConfirm: () => {
            if (typeof(fn_CallBack) === 'function') {
              window.setTimeout(_ => {
                fn_CallBack()
              }, 500)
            }
            // 알럿 props 초기화
            lv_Vm.alertProps = {}
            this.$bottomModal.close(lv_Alert)
          },
          onPopupClose: () => {
            // 알럿 props 초기화
            lv_Vm.alertProps = {}
            this.$bottomModal.close(lv_Alert)
          },
        }
      });

    },
    /**************************************************************************************
     * @function fn_SaveCompltAlert
     * @name     승인처리완료-안내
     * @notice   확인후, 지류동의서 승인 현황으로 이동한다.(MSPCM400M) 2023.04.19 황고은 선임 요청
    **************************************************************************************/
    fn_SaveCompltAlert () {
      const lv_Vm = this

      let lv_Alert = this.$bottomModal.open(MSPBottomAlert, {
        properties: lv_Vm.alertProps,
        listeners: {
          onPopupConfirm: () => {
            this.$bottomModal.close(lv_Alert)
            this.$router.push({name: 'MSPCM400M'})
          },
          onPopupClose: () => {
            // 알럿 props 초기화
            lv_Vm.alertProps = {}
            this.$bottomModal.close(lv_Alert)
          },
        }
      });
    },
    /***************************************************************
     * @function fn_BottomSheetHandler
     * @name     바텀시트_핸들링_함수
     * @param    sheetId : 바텀시트ID
     * @param    flag : close/open
     * @param    callFlag : CallbackFunction 실행 Flag
    ***************************************************************/
    fn_BottomSheetHandler (sheetId, flag) {
      if (flag === 'close') {
        switch (sheetId) { // BottomSheet Close
          case 'sheet1':
            this.$refs.sheet1.close()
            break;
          case 'sheet2-1':
            this.$refs.bottomSheet2.close()
            this.$refs.bottomSheet3.open()
            break;
          case 'sheet2-2':
            this.$refs.bottomSheet2.close()
            break;
          case 'sheet3':
            this.$refs.bottomSheet3.close()
            break;
        }
      } else {
        switch (sheetId) { // BottomSheet Open
          case 'sheet1':
            this.$refs.sheet1.open()
            break;
          case 'sheet2':
            this.$refs.bottomSheet2.open()
            break;
          case 'sheet3':
            this.$refs.bottomSheet3.open()
            break;
        }
      }
    },
  }, // ::Methods End
};
</script>
