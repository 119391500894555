/*
 * 업무구분 : 고객
 * 화 면 명 : MSPCM118P
 * 화면설명 : 고객명 검색
 * 작 성 일 : 2023.02.06
 * 작 성 자 : sh.park2022
 */
<template>
  <ur-page-container title="고객검색" :show-title="true" type="popup" class="msp">
    <ur-box-container direction="column" alignV="start" class="msp-cm-wrap">
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-info-area">

        <!-- 조직관련 COMPONENT : 관리자 접근시 노출 -->
        <ur-box-container v-if="lv_IsSrchOrgCombo" direction="column" alignV="start" class="ns-sch-area">
          <SearchCondOrgCM ref="refSearchOrg" v-model="lv_OrgSearchData" :pOrgData="lv_OrgSearchData" @selectResult="fn_SlectCnsltResultCM"/>
        </ur-box-container>

        <ur-box-container alignV="start" componentid="" direction="column" class="info-box pb20">
          <div class="info-title-wrap mb0">
            <span class="info-title fs14rem fwn crTy-bk7">고객명</span>
          </div>
          <ur-box-container alignV="start" componentid="" direction="column">
            <mo-text-field class="mb0" type="none" underline placeholder="고객명을 입력해주세요." v-model="lv_SearchCustNm" @keyup.enter="fn_ValidationCheck"/>  
          </ur-box-container>
        </ur-box-container>

      </ur-box-container>
      <ur-box-container v-if="ShowBottomBtn" alignV="start" componentid="" direction="column" class="ns-btn-relative">
        <div class="relative-div">
          <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_ResetBtnClick">초기화</mo-button>
          <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_ValidationCheck">조회</mo-button>
        </div>
      </ur-box-container>
    </ur-box-container>
  </ur-page-container>
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
import Msg from '@/systems/webkit/msg/msg'
import cmConstants from '@/config/constants/cmConstants.js'
import SearchCondOrgCM from '@/ui/cm/SearchCondOrgCM.vue'

import MSPCM114P from '@/ui/cm/MSPCM114P.vue'

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  // 현재 화면명
  name: "MSPCM181P",
  // 현재 화면 ID
  screenId: "MSPCM181P",
  // 컴포넌트 선언
  components: {
    SearchCondOrgCM
  },
  /***********************************************************************************
   * 화면 UI Property 정의
  ***********************************************************************************/
  props: {
    pOrgData: { // 최근 조회 조직
      type: Object,
      default: {}
    },
    pSearchCustNm: { // 고객명
      type: String,
      default: ''
    }
  },

  // bottom-sheet Full Popup
  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
  ***********************************************************************************/
  data() {
    return {
      lv_ReturnVal: {}, // 리턴값
      lv_ReturnStr: [], // 조회조건 텍스트 배열

      userInfo: {},
      lv_SearchCustNm: '', // 고객명
      lv_OdrStuCd: '',
      lv_OdrStuCds: cmConstants.APPLY_STATUS_CD,  // 신청현황
      lv_VipDcdGrdCd: '',
      lv_VipDcdGrdCds: cmConstants.APPLY_RATING_CD,  // VIP등급

      // 조직컴포넌트
      isShowCnslt: false, // 담당컨설턴트
      allChk: false, // 조직, 담당컨설턴트 조회완료 여부
      lv_OrgSearchData: {}, // 조직정보
      lv_RtnOrgSearchData: {}, // 조직정보
      lv_IsSrchOrgCombo: false, // 관리자여부

      lv_Popup114: {},
      lv_SearchCustInfo: {},

      ShowBottomBtn: true, // 키패드 노출시, 버튼 숨김
    };
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
  ***********************************************************************************/
  /** 설명 : Step-4 마운트 된 후 호출 */
  mounted () {
    const lv_Vm = this
    
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog('MSPCM118P')
    
    // ************ 키패드 이벤트 등록 ************ //
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.KEYBOARD_SHOW, () => {
      // 키패드 노출시 처리
      lv_Vm.ShowBottomBtn = false
    })
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.KEYBOARD_HIDE, () => {
      // 키패드 숨김시 처리
      lv_Vm.ShowBottomBtn = true
    })

    this.fn_Init()
  },
  destroyed() {
    // 키패드 이벤트 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.KEYBOARD_SHOW);
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.KEYBOARD_HIDE);
  },
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
  ***********************************************************************************/
  methods: {
    /*********************************************************
     * @function fn_Init
     * @notice   화면진입시, 최초 실행 함수
     *           초기 셋팅 및 설정
    *********************************************************/
    fn_Init () {
      this.userInfo = this.getStore('userInfo').getters.getUserInfo
      this.lv_IsSrchOrgCombo = this.userInfo.isSrchOrgCombo === 'Y' ? true : false

      console.log('userInfo', this.userInfo)

      // 부모화면으로부터 전달받은 파라미터를 설정
      this.lv_OrgSearchData = _.cloneDeep(this.$props.pOrgData) // 조직정보
      this.lv_SearchCustNm = _.cloneDeep(this.$props.pSearchCustNm) // 고객명
    },
    /******************************************************************************
     * @function  fn_SlectCnsltResultCM()
     * @notice    조직 변경시, 전역변수에 셋팅
     *              담당컨설턴트로 인해 두번 셋팅을 시도하여, 마지막에 셋팅 처리
     * isShowCnslt: 조직, 담당컨설턴트까지 모두 조회후, 셋팅하였는지 여부
     * allChk: 조회버튼 활성화/비활성화 여부
    ******************************************************************************/
    fn_SlectCnsltResultCM (pData) {
      if ( this.isShowCnslt ) {
        // this.lv_OrgSearchData = pData
        this.lv_RtnOrgSearchData = pData
        this.allChk = true
      }
      this.isShowCnslt = true
    },
    /******************************************************************************
     * @function  fn_ResetBtnClick
     * @notice    상세조회 조건 초기화
     *              (상세조회 내 초기화 버튼 클릭시 호출)
     * @param {MouseEvent} e - MouseEvent 이벤트
     * @return {none} - 반환없음
    ******************************************************************************/
    fn_ResetBtnClick (e) {
      this.$refs.refSearchOrg.fn_ClearOrg()
      // 조직
      this.lv_OrgSearchData = {}
      this.lv_RtnOrgSearchData = {}
      this.lv_SearchCustNm = '' // 고객명
    },
    /******************************************************************************
     * @function fn_ValidationCheck
     * @notice   상세조회조건 유효성 체크
    ******************************************************************************/
    fn_ValidationCheck () {
      // 조직 콤보박스 권한자인 경우 컨설턴트 입력여부 체크
      if ( this.lv_IsSrchOrgCombo ) {
        // 담당 컨설턴트 전체 조회 안됨
        if ( !_.isEmpty(this.lv_RtnOrgSearchData.cnslt) && this.lv_RtnOrgSearchData.cnslt.key === '0') {
          if (this.userInfo.onpstFofOrgNo !== this.lv_RtnOrgSearchData.fofOrg.key) {
            this.getStore('confirm').dispatch('ADD', this.$t('pm')['EPMC005'])  // '정보보호 요청 대상건 입니다.'
          }
        } else {
          if (!this.$bizUtil.isEmpty(this.lv_SearchCustNm)){
            // 지정한 조건으로 상세조회
            this.fn_Search()
          } else {
            let alertMsg = '고객명을 입력해주세요.'
            this.getStore('confirm').dispatch('ADD', alertMsg)
          }
        }
      }
    },
    /******************************************************************************
     * @function fn_Search
     * @notice   상세조회조건을 설정 후 부모화면에의 조회함수 호출
     * @param {MouseEvent} e - MouseEvent 이벤트
     * @return {none} - 반환없음
     ******************************************************************************/
    fn_Search () {
      const lv_Vm = this

      let cnsltNo = this.lv_RtnOrgSearchData.cnslt.key

      this.lv_Popup114 = this.$bottomModal.open(MSPCM114P, {
        properties: {
          title: '고객검색',
          pSrnId: 'MSPCM118P',
          pCustNm: this.lv_SearchCustNm,
          pCnsltNo: cnsltNo
        },
        listeners: {
          onPopupConfirm: (pData) => {
            this.$bottomModal.close(this.lv_Popup114);
            Object.assign(lv_Vm.lv_SearchCustInfo, pData)
            console.log('this.lv_SearchCustInfo', lv_Vm.lv_SearchCustInfo)

            lv_Vm.fn_SetCustInfo()
          },
          onPopupClose: () => {
            this.$bottomModal.close(this.lv_Popup114);
          }
        }
      });
    },
    /******************************************************************************
     * @function fn_SetCustInfo
     * @notice   조회한 고객정보 부모 화면에 전달
    ******************************************************************************/
    fn_SetCustInfo () {
      this.lv_SearchCustInfo.orgData = this.lv_RtnOrgSearchData
      
      this.$emit('onPopupConfirm', this.lv_SearchCustInfo)
    },
  }
};
</script>
<style scoped>
</style>