/*
 * 업무구분: 고객
 * 화 면 명: MSPCM114P
 * 화면설명: 고객선택 팝업
 * 접근권한: 지점장
 * 작 성 일: 2022.07.08
 * 작 성 자: 조한결
 */
<template>
  <ur-page-container title="고객검색" type="subpage" class="msp" :show-title="true">
    <ur-box-container direction="column" alignV="start" class="ns-list-area">
      <ur-box-container alignV="start" componentid="ur_box_container_003" direction="column" class="ns-info-sum">
        <mo-list-item>
          <div class="list-item__contents">
            <div class="list-item__contents__title">
              <span class="sum">총<span class="count">{{lv_ListData.length}}</span>명</span>
            </div>
          </div>
        </mo-list-item>
      </ur-box-container>
      <ur-box-container direction="column" alignV="start" componentid="ur_box_container_001" class="ns-radio-list2 mb22 customer-list-ty">
          <mo-list :list-data="lv_ListData">
            <template #list-item="{item}">
              <mo-list-item>
                <mo-radio v-model="lv_SelectedItem" :value="item.chnlCustId" @click="fn_ListClick(item.chnlCustId)" />
                <div class="list-item__contents">
                  <div class="list-item__contents__title">
                    <span class="name">{{item.custNm}}</span>
                    <mo-badge class="badge-sample-badge" text="본인" shape="status">{{item.chnlCustScCdNm}}</mo-badge>
                  </div>
                  <div class="list-item__contents__info">
                    <span>{{item.birthNo}}</span><em>|</em><span>{{item.sxdsCdNm}}</span>
                  </div>
                  <div class="list-item__contents__info">
                    <span>주고객<span class="ml10">{{item.mnCustNm}}</span></span><em>|</em><span>관계<span class="ml10">{{item.mnCustRltnCdNm}}</span></span>
                  </div>
                  <div class="list-item__contents__info">
                    <span>연락처<span class="ml10">{{item.celno}}</span></span>
                  </div>
                  <div class="list-item__contents__info">
                    <span>미승인 고객여부<b :class="{'crTy-blue2':item.unAprvCustYn==='Y', 'crTy-orange2':item.unAprvCustYn==='N'}" class="pl10">{{item.unAprvCustYn}}</b></span>
                  </div>
                </div>
              </mo-list-item>
            </template>
          </mo-list>
        </ur-box-container>
  
      
      <ur-box-container alignV="start" componentid="ur_box_container_003" direction="column" class="ns-radio-list2 no-data" v-if="lv_ListData.length === 0">
        <mo-list-item>
          <div class="list-item__contents">
            <div class="list-item__contents__info">
              <span>데이터가 존재하지 않습니다.</span>
            </div>
          </div>
        </mo-list-item>
      </ur-box-container>
      

      <ur-box-container alignV="start" componentid="ur_box_container_05" direction="column" class="ns-btn-relative">
        <div class="relative-div">
        <mo-button componentid="mo_button_004" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_Cancel">취소</mo-button>
        <mo-button componentid="mo_button_005" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_Confirmed">확인</mo-button>
        </div>
      </ur-box-container>

    </ur-box-container>
  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import MspBottomSelect from '@/ui/_cmm/components/MspBottomSelect.vue'
  import Msg from '@/systems/webkit/msg/msg'
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPCM114P",
    screenId: "MSPCM114P",
    components: {
      MspBottomSelect
    },
    props: {
    pCustNm: String, // 고객명
    pCnsltNo: String, // 컨설턴트 ID
      callbackFn: {
        type: Function,
        default: () => false
      }
    },
    // bottom-sheet Full Popup
    modalProps: {
      mode: 'right' // 우->좌 open popup (defalut: 하->상)
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
      console.log('this.pCustNm', this.pCustNm)
      console.log('this.pCnsltNo', this.pCnsltNo)
      this.fn_Init()
      window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_ClosePopup)
    },
    mounted() {
      this.$bizUtil.insSrnLog('MSPCM114P')
    },
    beforeDestroy () {
      // Back Key 해지
      window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_ClosePopup)
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
      lv_SearchKeyword: '', // 입력한 고객명
      lv_SearchCnsltNo: '', // 조회할 컨설턴트 ID - 부모화면에서 받아와야함
      lv_ListData: [], // 조회된 데이타
      lv_SelectedItem: '', // 선택한 Row의 데이타
      lv_ViewType: '0', // 0:빈화면, 1:고객명 입력 후 검색해 주세요. , 2:검색결과가 존재하지 않습니다.
      isEmptyView: false,
      isOneOrMoreCheck: false,
      lv_SearchParam: {}
      };
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {},
    /***********************************************************************************
     * watch 정의 영역
     ***********************************************************************************/
    watch: {},
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
    /*********************************************************
     * Function명: fn_Init
     * 설명: 최초접근 초기화 프로세스
     * Params: N/A
     * Return: N/A
     *********************************************************/
    fn_Init () {
      if (this.pCnsltNo && this.pCnsltNo.length > 0) {
        this.lv_SearchCnsltNo = this.pCnsltNo // 컨설턴트 ID
      }
      if (this.pCustNm && this.pCustNm.length > 0) {
        this.lv_SearchKeyword = this.pCustNm // 고객명
      }
      // 고객검색
      this.fn_Search()
    },
    /*********************************************************
     * Function명: fn_Search
     * 설명: 고객검색
     * Params: N/A
     * Return: N/A
     *********************************************************/
    fn_Search () {
      this.lv_ListData = [] // 검색된 리스트 데이타 초기화
      this.lv_SelectedItem = '' // 선택된 데이터 초기화 
      if (!!this.lv_SearchCnsltNo.trim() && !!this.lv_SearchKeyword.trim()) {
        let lv_Vm = this
        let trnstId = 'txTSSCM82S2'
        let pParams = {}
        pParams.custNm = this.lv_SearchKeyword // 고객명
        pParams.cnsltNo = this.lv_SearchCnsltNo // 컨설턴트 ID
        pParams.infoPrcusCnsntStatCd = '01' // 정보활용동의 처리상태코드 - 01 : 신청 , 02 : 승인 , 03 : 승인취소
        lv_Vm.lv_ViewType = '0'
        // 고객검색
        this.post(lv_Vm, pParams, trnstId, 'S')
          .then(function (res) {
            console.log('response :::', res.body)
            if (res && res.body && res.body.cMInfoPrcusCnsntListVO) {
              lv_Vm.lv_ListData = res.body.cMInfoPrcusCnsntListVO
              if (lv_Vm.lv_ListData.length < 1) {
                lv_Vm.lv_ViewType = '2'
              }
            } else {
              lv_Vm.lv_ViewType = '2'
            }
          })
          .catch(function (error) {
            window.vue.error(error)
          })
      } else {
        this.lv_SearchKeyword = '' // 고객명 데이타 초기화
        this.lv_ViewType = '1'
      }
    },
    /*********************************************************
     * Function명: fn_ListClick
     * 설명: 리스트 Row 선택 시
     * Params: N/A
     * Return: N/A
     *********************************************************/
    fn_ListClick (idx) {
      console.log('fn_ListClick', idx)
      this.lv_SelectedItem = idx
      console.log('lv_ListData', this.lv_ListData)
      this.lv_SearchParam = {}
      let findIdx = this.lv_ListData.findIndex(obj => obj.chnlCustId === this.lv_SelectedItem)
      if (findIdx !== -1) {
        console.log('this.lv_ListData[findIdx]', this.lv_ListData[findIdx])
        Object.assign(this.lv_SearchParam, this.lv_ListData[findIdx])
      }
      console.log('this.lv_SearchParam', this.lv_SearchParam)
    },
    /*********************************************************
     * Function명: fn_Confirmed
     * 설명: 확인버튼 선택
     * Params: N/A
     * Return: N/A
     *********************************************************/
    fn_Confirmed () {
      console.log('확인버튼 클릭')
      let lv_Vm = this
      let lv_pParams = {}
      Object.assign(lv_pParams, this.lv_SearchParam)
      lv_pParams.cnsltNo = this.pCnsltNo
      console.log('114 lv_pParams ::', lv_pParams)
      lv_Vm.$emit('onPopupConfirm', lv_pParams)
    },
    /*********************************************************
     * Function명: fn_Cancel
     * 설명: 취소버튼 선택
     * Params: N/A
     * Return: N/A
     *********************************************************/
    fn_Cancel () {
      console.log('취소버튼 클릭')
      let lv_Vm = this
      lv_Vm.$emit('onPopupClose')
    },
    /*********************************************************
     * Function명: fn_ClosePopup
     * 설명: 팝업 닫기
     * Params: N/A
     * Return: N/A
     *********************************************************/
    fn_ClosePopup () {
      this.callbackFn(null)
      this.$emit('onPopupConfirm')
    },
  },
}    
</script>
<style scoped>
</style>